import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import * as React from "react";
import { useForm } from "react-hook-form";
import {
  AdminAuthorizationMutationVariables,
  MeDocument,
  MeQuery,
  useAdminAuthorizationMutation,
} from "../../generated/graphql";
import { PageMeComp, ssrMe } from "../../generated/page";

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { props } = await ssrMe.getServerPage({}, ctx, true);

  if (props.data.me) {
    return {
      redirect: {
        destination: "/pilots",
        permanent: true,
      },
    };
  }
  return { props };
};

const SignIn: PageMeComp = () => {
  const router = useRouter();

  const getIPFromAmazon = (variables) => {
    fetch("https://checkip.amazonaws.com/")
      .then((res) => res.text())
      .then((data) => {
        signIn({
          variables: {
            ...variables,
            ipAddress: data,
          },
        });
      })
      .catch((e) => alert(e));
  };

  const { register, handleSubmit, errors, setError } =
    useForm<AdminAuthorizationMutationVariables>({
      defaultValues: {
        email: "",
        password: "",
        ipAddress: "",
      },
    });

  const [signIn] = useAdminAuthorizationMutation({
    update: (client, { data }) => {
      if (data && data.adminAuthorization) {
        const { admin, jwt, errors } = data.adminAuthorization;

        if (admin) {
          client.writeQuery<MeQuery>({
            query: MeDocument,
            data: {
              me: admin,
            },
          });

          setCookie(null, process.env.COOKIE, jwt, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          });
        } else {
          errors.forEach((error) => {
            setError("email", {
              type: "manual",
              message: error.detail,
            });
          });
        }
      }
    },
    onCompleted: (data) => {
      if (data && data.adminAuthorization) {
        const { admin, errors } = data.adminAuthorization;

        if (admin) {
          router.push("/");
        } else if (errors) {
          alert("error: " + errors[0].detail);
        }
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form
          className="mt-8"
          method="POST"
          onSubmit={handleSubmit(
            (variables: AdminAuthorizationMutationVariables) => {
              signIn({
                variables: {
                  ...variables,
                  ipAddress: "",
                },
              });
            }
          )}
        >
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <input
                ref={register}
                aria-label="Email address"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Email address"
              />
            </div>
            <div className="-mt-px">
              <input
                ref={register}
                aria-label="Password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Password"
              />
            </div>
          </div>
          {(errors.email || errors.password) && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {errors.email.message}
            </p>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;

